import React from "react";

const ArrowUp = ({ color }) => {
  const colorArrow = color !== "" ? color : "black";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 40, transform: "rotate(-90deg)" }}
      viewBox="0 0 176.28 273.89"
    >
      {console.log(color)}
      <title>Element 3</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_2-2" data-name="Ebene 2">
          <path
            className="cls-1"
            style={{ fill: colorArrow }}
            d="M151.54,150.75c-.12.14-.25.27-.39.41l-13.57,13.57-27.15-27.15L5.63,32.78A19.19,19.19,0,1,1,32.77,5.63L151.15,124A19.19,19.19,0,0,1,151.54,150.75Z"
          />
          <path
            className="cls-2"
            style={{ fill: colorArrow, opacity: 0.4 }}
            d="M137.58,164.73,34.69,267.62A19.19,19.19,0,1,1,7.55,240.47L110.43,137.58Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowUp;
