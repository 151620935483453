import React, { useState } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import useWindowDimensions from "../components/hooks/useWindowDimensions";
import ScrollToTop from "react-scroll-up";
import ArrowUp from "../components/common/arrowup";

import "../styles/templates/portfolio.sass";

const Portfolio = ({ pageContext, location }) => {
  const { slugref, locale } = pageContext;
  const { home, portfolio } = pageContext.data.data;
  const { innerWidth } = useWindowDimensions();
  const [toggle, setToggle] = useState(false);

  const portfolioItems = [];
  for (let i = 0; i < Math.round(portfolio.pageInfo.itemCount / 2); i++) {
    portfolioItems.push(i);
  }

  if (typeof window === `undefined`) return <></>;
  return (
    <Layout
      location={location}
      data={home}
      toggle={toggle}
      setToggle={setToggle}
      slugref={slugref}
    >
      <section className="portfolio-wrapper">
        {portfolioItems.map((item, index) => {
          let count = index * 2;
          return index % 2 === 0 ? (
            <section
              key={index}
              className="pageOption mg od"
              style={{
                height: 450,
                paddingTop: portfolioItems.length === 1 ? "30px" : "",
              }}
              data-sal="slide-up"
              data-sal-delay={(index + 1) * 300}
              data-sal-easing="ease"
              data-sal-duration="1000"
              data-color-left={
                portfolio.nodes[count] !== undefined
                  ? portfolio.nodes[count].colorminor.hex
                  : ""
              }
              data-color-right={
                portfolio.nodes[count + 1] !== undefined
                  ? portfolio.nodes[count + 1].colorminor.hex
                  : ""
              }
              data-inf-left={
                portfolio.nodes[count] !== undefined
                  ? portfolio.nodes[count].titel
                  : ""
              }
              data-inf-right={
                portfolio.nodes[count + 1] !== undefined
                  ? portfolio.nodes[count + 1].titel
                  : ""
              }
            >
              {portfolio.nodes[count] !== undefined && (
                <>
                  <Link
                    key={portfolio.nodes[count].slug}
                    to={
                      locale === "de"
                        ? `/portfolio/${portfolio.nodes[count].slug}`
                        : `/en/portfolio/${portfolio.nodes[count].slugref}`
                    }
                    className="pf_option skew-right-left"
                    style={{
                      width: `${0.5 * innerWidth +
                        324.7543 +
                        (0.725 * 540 - 456.75)}px`,
                      left: 0,
                      backgroundColor: portfolio.nodes[count].colorminor.hex,
                    }}
                  >
                    <div
                      className="pf___title left2right"
                      style={{
                        top: 50,
                        right: `${(0.5 * innerWidth +
                          324.7543 +
                          (0.725 * 540 - 456.75)) /
                          4 +
                          (0.24 * innerWidth - 221.08)}px`,
                      }}
                    >
                      <h1 className="mb0">{portfolio.nodes[count].titel}</h1>
                      <h2 className="mb0 mt0">
                        {portfolio.nodes[count].subtitel}
                      </h2>
                    </div>
                    <img
                      // fluid={data.imageOne.childImageSharp.fluid}
                      src={`${portfolio.nodes[count].coverImage.fluid.src}`}
                      alt=""
                    />
                  </Link>
                </>
              )}
              {portfolio.nodes[count + 1] !== undefined && (
                <Link
                  key={portfolio.nodes[count + 1].slug}
                  to={
                    locale === "de"
                      ? `/portfolio/${portfolio.nodes[count + 1].slug}`
                      : `/en/portfolio/${portfolio.nodes[count + 1].slugref}`
                  }
                  className="pf_option skew-right-left"
                  style={{
                    width: `${0.5 * innerWidth +
                      324.7543 +
                      (0.725 * 540 - 456.75)}px`,
                    right: 0,
                    backgroundColor: portfolio.nodes[count + 1].colorminor.hex,
                  }}
                >
                  <div
                    className="pf___title left2right"
                    style={{
                      bottom: 50,
                      left: `${(0.5 * innerWidth +
                        324.7543 +
                        (0.725 * 540 - 456.75)) /
                        4 +
                        (0.24 * innerWidth - 121.08)}px`,
                    }}
                  >
                    <h1 className="mt0 mb0">
                      {portfolio.nodes[count + 1].titel}
                    </h1>
                    <h2 className="mt0">
                      {portfolio.nodes[count + 1].subtitel}
                    </h2>
                  </div>
                  <img
                    // fluid={data.imageTwo.childImageSharp.fluid}
                    src={portfolio.nodes[count + 1].coverImage.fluid.src}
                    styles={{ minHeight: "" }}
                    alt=""
                  />
                </Link>
              )}
              {portfolio.nodes[count + 1] === undefined && <Link to="#"></Link>}
            </section>
          ) : (
            <section
              key={index}
              className="pageOption mg ev"
              style={{ height: 450 }}
              data-sal="slide-up"
              data-sal-delay={(index + 1) * 300}
              data-sal-easing="ease"
              data-sal-duration="1000"
              data-color-left={
                portfolio.nodes[count] !== undefined
                  ? portfolio.nodes[count].colorminor.hex
                  : ""
              }
              data-color-right={
                portfolio.nodes[count + 1] !== undefined
                  ? portfolio.nodes[count + 1].colorminor.hex
                  : ""
              }
              data-inf-left={
                portfolio.nodes[count] !== undefined
                  ? portfolio.nodes[count].titel
                  : ""
              }
              data-inf-right={
                portfolio.nodes[count + 1] !== undefined
                  ? portfolio.nodes[count + 1].titel
                  : ""
              }
            >
              {portfolio.nodes[count] !== undefined && (
                <Link
                  key={portfolio.nodes[count].slug}
                  to={
                    locale === "de"
                      ? `/portfolio/${portfolio.nodes[count].slug}`
                      : `/en/portfolio/${portfolio.nodes[count].slugref}`
                  }
                  className="pf_option skew-left-right"
                  style={{
                    width: `${0.5 * innerWidth +
                      324.7543 +
                      (0.725 * 540 - 456.75)}px`,
                    left: 0,
                    backgroundColor: portfolio.nodes[count].colorminor.hex,
                  }}
                >
                  <div
                    className="pf___title right2left"
                    style={{
                      bottom: 50,
                      right: `${(0.5 * innerWidth +
                        324.7543 +
                        (0.725 * 540 - 456.75)) /
                        4 +
                        (0.24 * innerWidth - 221.08)}px`,
                    }}
                  >
                    <h1 className="mb0">{portfolio.nodes[count].titel}</h1>
                    <h2 className="mt0">{portfolio.nodes[count].subtitel}</h2>
                  </div>
                  <img
                    // fluid={data.imageOne.childImageSharp.fluid}
                    src={portfolio.nodes[count].coverImage.fluid.src}
                    alt=""
                  />
                </Link>
              )}
              {portfolio.nodes[count + 1] !== undefined && (
                <Link
                  key={portfolio.nodes[count + 1].slug}
                  to={
                    locale === "de"
                      ? `/portfolio/${portfolio.nodes[count + 1].slug}`
                      : `/en/portfolio/${portfolio.nodes[count + 1].slugref}`
                  }
                  className="pf_option skew-left-right"
                  style={{
                    width: `${0.5 * innerWidth +
                      324.7543 +
                      (0.725 * 540 - 456.75)}px`,
                    right: 0,
                    backgroundColor: portfolio.nodes[count + 1].colorminor.hex,
                  }}
                >
                  <div
                    className="pf___title right2left"
                    style={{
                      top: 50,
                      left: `${(0.5 * innerWidth +
                        324.7543 +
                        (0.725 * 540 - 456.75)) /
                        4 +
                        (0.24 * innerWidth - 121.08)}px`,
                    }}
                  >
                    <h1 className="mb0">{portfolio.nodes[count + 1].titel}</h1>
                    <h2 className="mb0 mt0">
                      {portfolio.nodes[count + 1].subtitel}
                    </h2>
                  </div>
                  <img
                    src={portfolio.nodes[count + 1].coverImage.fluid.src}
                    alt=""
                  />
                </Link>
              )}
              {portfolio.nodes[count + 1] === undefined && <Link to="#"></Link>}
            </section>
          );
        })}
      </section>
      <ScrollToTop
        style={{
          position: "fixed",
          bottom: 50,
          right: 100,
          cursor: "pointer",
          transitionDuration: "0.2s",
          transitionTimingFunction: "linear",
          transitionDelay: "0s",
          zIndex: "10",
        }}
        showUnder={160}
      >
        <ArrowUp />
      </ScrollToTop>
    </Layout>
  );
};

export default Portfolio;
